@use '@/styles/_palettes.module.scss' as palettes;
@use '@/styles/_styles.module.scss' as styles;

.share-guest-banner {
  width: 100%;
  color: palettes.$palette-true-white;
  background-color: palettes.$palette-coral-red;
  min-height: styles.$banner-height;
  font-size: styles.$font-size-main;
  top: styles.$header-height;
  text-align: center;
  display: flex;
  justify-items: center;
  justify-content: center;

  & p {
    margin: 0;
    color: palettes.$palette-true-white;
  }
}

.share-guest-banner-container {
  margin: 10px;
}
