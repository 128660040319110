@use '@/styles/colors.module' as colors;
@use '@/styles/palettes.module' as palettes;
@use '@/styles/styles.module' as styles;
@use '@/styles/mixins.module' as mixins;

.setting-audio-dialog-container {
  display: flex;
  padding: 10px 20px 20px 20px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.setting-audio-dialog-col-wrapper {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid
    var(--border-secondary-on-surface, rgba(palettes.$palette-true-white, 0.32));
}

.setting-audio-dialog-col-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  & span {
    font-size: styles.$setting-audio-description-font-size;
    font-weight: 400;
  }
}

.setting-audio-dialog-description {
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  font-size: styles.$setting-audio-title-font-size;
  font-weight: 700;
  line-height: 16px;
  & img {
    display: flex;
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
  }
}

.device-name {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: stretch;

  & select {
    padding: 10px 30px 10px 16px;
    appearance: none;
    -webkit-appearance: none;
    color: palettes.$palette-true-white;
    outline: none;
    border: none;
    border-radius: 5px;
    background-color: colors.$color-modal-dialog-bg;
    text-overflow: ellipsis;
    border-radius: 5px;
    border: 1px solid palettes.$palette-true-white;
    width: 100%;
  }

  & > select:disabled {
    color: rgba(colors.$color-disabled-label, 0.32);
    border: 1px solid rgba(colors.$color-disabled-label, 0.32);
    background: rgba(colors.$color-disabled-label, 0.12);
  }

  & option {
    background: colors.$color-footer-bg;
  }

  & select:enabled:hover {
    cursor: pointer;
    filter: brightness(1.2);
  }

  & select:enabled:active {
    background: var(
      --fill-secondary-active,
      rgba(palettes.$palette-true-white, 0.32)
    );
  }

  &::after {
    content: url(../../../assets/chevron_down.svg);
    position: absolute;
    right: 10px;
    top: 6px;
    pointer-events: none;
  }

  &.disabled::after {
    content: url(../../../assets/chevron_down_disabled.svg);
  }
}

.output-device-list {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-self: stretch;
}

.mic-permission {
  display: flex;
  min-width: 160px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  font-size: styles.$setting-audio-description-font-size;
  font-weight: 400;

  & button {
    width: 100%;
    padding: 8px 20px;
    line-height: 1.41;
    font-weight: 700;
    color: colors.$color-modal-dialog-button-label;
    background: colors.$color-modal-dialog-bg;

    border-radius: 5px;
    border: 1px solid colors.$color-modal-dialog-button-label;

    &:hover {
      cursor: pointer;
      background-color: colors.$color-modal-popup-bg-hover;
    }
  }
}

ul.vad-menu-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  align-self: stretch;
  padding-left: 0;
  margin: 0;
  -webkit-tap-highlight-color: transparent;

  & button {
    cursor: pointer;
  }

  & li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;

    &:not(:disabled):hover {
      cursor: pointer;
    }

    & p {
      margin: 0;
      align-self: stretch;

      font-size: styles.$setting-audio-description-font-size;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    & button {
      color: palettes.$palette-true-white;
      width: 100%;
    }
  }
}

.vad-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;

  .vad-item-label {
    display: flex;
    padding: 4px;
    align-items: center;
    gap: 4px;
  }

  & span {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;

    overflow: hidden;
    color: palettes.$palette-true-white;
    text-overflow: ellipsis;
    font-size: styles.$setting-audio-title-font-size;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
  }
}

.vad-helper-text {
  display: flex;
  padding-left: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.vad-item-unchecked-icon {
  content: url(../../../assets/radio_unchecked.svg);
  display: flex;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
}

.vad-item-checked-icon {
  content: url(../../../assets/radio_checked.svg);
  display: flex;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
}
