@use '@/styles/palettes.module' as palettes;
@use '@/styles/colors.module' as colors;
@use '@/styles/styles.module' as styles;
@use '@/styles/mixins.module' as mixins;

.font-size {
  background: none;
  border: 0;
  gap: 2px;
  color: colors.$color-button-label;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: styles.$footer-button-font-size;
  text-align: center;

  & div {
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
  }

  & .font-size-icon {
    mask: url('../../../assets/font-size.svg') no-repeat center center / contain;
    -webkit-mask: url('../../../assets/font-size.svg') no-repeat center center /
      contain;
    background: colors.$color-font-size;
  }

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      & .font-size-icon {
        background: colors.$color-font-size-hover;
      }
      color: colors.$color-font-size-hover;
    }
  }

  &:active {
    & .font-size-icon {
      background: colors.$color-font-size-active;
    }
    filter: contrast(100%);
    color: colors.$color-font-size-active;
  }
}

.font-size-slider {
  transform: translateX(-48px) rotate(-90deg);
  transform-origin: top right;
  border-radius: 24px;
  position: relative;
  bottom: 350px;

  & > ul {
    width: 280px;
    height: 48px;
    display: flex;

    & > li {
      display: flex;
      align-items: center;
      width: fit-content;
      height: fit-content;
      margin: auto;
      padding: 0;
    }
  }
}

.font-size-contents {
  @include mixins.popup-details-contents;
}
