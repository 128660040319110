.campaign-banner {
  width: 150px;
  height: 100%;

  background-repeat: no-repeat;
  background-size: auto 90%;
  background-position: center center;
  background-image: url('../../../assets/campaign_banner.svg');

  &:hover {
    cursor: pointer;
    filter: brightness(110%);
  }

  &:active {
    filter: brightness(85%);
  }
}
