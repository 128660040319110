@use '../../../styles/palettes.module' as palettes;
@use '../../../styles/colors.module' as colors;
@use '../../../styles/styles.module' as styles;
@use '../../../styles/mixins.module' as mixins;

.agreement {
  display: flex;
  flex-direction: column;
  padding: 80px 30px;
  align-items: center;
  flex: 1 0 0;
  gap: 25px;
}

.agreement-text-container {
  display: flex;
  min-width: 280px;
  max-width: 420px;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  & p {
    margin: 0;
  }

  & .agreement-title {
    & > p {
      font-size: styles.$font-size-title;
    }
  }

  & .agreement-text {
    & > p {
      font-size: styles.$font-size-main;
      line-height: styles.$line-height-main;
    }
  }
}

.agreement-body {
  display: flex;
  min-width: 280px;
  max-width: 420px;
  flex-direction: column;
  justify-content: center;
  gap: 25px;

  & .term-of-service {
    text-align: center;
  }

  & button {
    // 利用規約画面内ボタン共通設定
    font-size: styles.$font-size-button;
    line-height: styles.$button-line-height;
    font-weight: 700;
    width: 270px;
    min-width: 108px;
    height: 48px;
    padding: 12px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
}

.agreement-checkbox {
  color: palettes.$palette-true-white;
  font-size: styles.$font-size-main;
  line-height: styles.$line-height-main;

  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  & > input[type='checkbox'] {
    display: none;
  }

  & > label {
    display: flex;
    padding: 3px 3px 3px 32px;
    align-items: center;
    position: relative;
    cursor: pointer;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 5px;
      width: 16px;
      height: 16px;
      border-radius: 3px;
      border: 2px solid palettes.$palette-true-white;
      transform: translateY(-50%);
    }
  }

  & > input[type='checkbox']:checked + label::after {
    content: '';
    display: block;
    position: absolute;
    left: 11px;
    transform: rotate(45deg);
    width: 5px;
    height: 10px;
    border: solid palettes.$palette-true-white;
    border-width: 0 3px 3px 0;
  }
}

.agree-btn {
  display: flex;
  justify-content: center;
  align-items: center;

  // ボタン毎に見た目が異なるため個別で設定する
  @include mixins.common_blue_button_style;
}

.disagree-btn {
  display: flex;
  justify-content: center;
  align-items: center;

  // ボタン毎に見た目が異なるため個別で設定する
  & button {
    border: 1px solid colors.$color-disagree-button-border;
    color: colors.$color-disagree-button-label;
    background: transparent;
    width: 100%;
    min-width: 240px;

    &:hover {
      background-color: colors.$color-disagree-button-hover;
    }

    &:active {
      background-color: colors.$color-disagree-button-active;
    }
  }
}

.agreement-form-error {
  & p {
    font-size: styles.$font-size-main;
    line-height: styles.$line-height-main;
  }
}
