@use '@/styles/palettes.module' as palettes;
@use '@/styles/colors.module' as colors;
@use '@/styles/styles.module' as styles;

.language-select-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  & p {
    height: 18px;
    margin: 0;
  }
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    height: 2rem;
  }
}

.language-select-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;

  & button {
    display: flex;
    width: 100%;
    min-width: 240px;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.select-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.lang-list {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 5px;

  & p {
    margin: 0;
  }

  & select {
    color: palettes.$palette-true-white;
    outline: none;
    border: none;
    appearance: none;
    -webkit-appearance: none;
    background-color: colors.$color-modal-lang-select;
    text-align: left;
    text-align-last: center;
    text-overflow: ellipsis;
    padding: 12px 0;
    font-size: styles.$modal-dialog-language-select-font-size;
    line-height: 1.85;
    width: 100%;
    border-radius: styles.$header-button-radius;
  }

  & select:only-child {
    padding: 12px 20px 12px 20px;
  }

  & select:hover {
    cursor: pointer;
    filter: brightness(1.2);
  }
}

.lang-list-select-span {
  position: relative;
  width: 100%;

  &::after {
    content: url(../../../assets/chevron_down.svg);
    position: absolute;
    right: 10px;
    top: 15px;
    pointer-events: none;
  }
}

.language-select-note-message {
  margin-bottom: 12px;
}

.interactive-check-wrapper {
  padding: 20px 0;
  display: flex;
  align-items: center;
  gap: 15px;

  font-size: styles.$toggle-label-font-size;
  line-height: styles.$toggle-label-line-height;
}

.interactive-caution {
  height: 18px;
  width: 100%;
}

.interactive-check-label-disabled {
  opacity: styles.$toggle-disabled-opacity;
}
