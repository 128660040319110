@use '@/styles/colors.module' as colors;
@use '@/styles/palettes.module' as palettes;
@use '@/styles/styles.module' as styles;

.content {
  font-size: styles.$font-size-recognized-default-text;
  text-align: center;
  margin: 0 15px 0 20px;
  height: 100%;
}

.footer-close {
  height: 100%;
  max-height: calc(100% - env(safe-area-inset-bottom));
  transition: max-height 0.3s;
}

.footer-open {
  max-height: calc(100% - styles.$footer-host-safe-area-height);
  transition: max-height 0.3s;
}

.footer-guest {
  max-height: calc(100% - styles.$footer-guest-height);
}

.free-plan-footer-close {
  height: 100%;
  max-height: calc(100% - env(safe-area-inset-bottom));
  transition: max-height 0.3s;
}

.free-plan-footer-open {
  max-height: calc(
    100% - env(safe-area-inset-bottom) - styles.$footer-host-height
  );
  transition: max-height 0.3s;
}

@media screen and (max-width: 1023px) {
  .footer-close-shared {
    max-height: calc(100% - 40px - env(safe-area-inset-bottom));
  }

  .footer-open-shared {
    max-height: calc(100% - 40px - styles.$footer-host-safe-area-height);
  }
}

.text > .one-phrase {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.conference {
  height: 100%;
}

.text > .list {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.text ul {
  list-style: none;
  padding-inline-start: 0;
  margin-block: 0;
}

.text ul > li {
  width: 100%;
  border-bottom: 0.5px solid var(palettes.$palette-hex-gray);
}

.text.conference ul > li {
  border: none;
}

.text ul.recognized > li:last-child {
  border-bottom: none;
}

.text > .list > .recognized > li {
  display: flex;
  flex-direction: row;

  & div {
    width: 45%;
    margin: 0 10px 0 10px;
  }
}

.interim {
  width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  color: colors.$color-list-view-interim-ttt-letter;

  & div {
    margin: 0 10px 0 10px;
    height: calc(100% + 40px);
  }
}

.interim-reversed {
  color: colors.$color-list-view-interim-stt-letter;
}

.one-phrase {
  & .recognized {
    max-height: 100%;
  }

  // フッター非表示時や固定暫定テキスト表示時で高さを変更する
  & .footer-open-fixed-interim-recognized {
    max-height: calc(100% - 50px);
  }
  & .footer-close-fixed-interim-recognized {
    max-height: calc(100% - 80px);
  }
}

.list {
  & .recognized {
    max-height: 100%;
  }

  // フッター非表示時や固定暫定テキスト表示時で高さを変更する
  & .footer-open-fixed-interim-recognized {
    max-height: calc(100% - 50px);
  }
  & .footer-close-fixed-interim-recognized {
    max-height: calc(100% - 80px);
  }
}

.recognized {
  color: palettes.$palette-true-white;
  overflow-y: scroll;

  // firefoxのスクロールバー設定
  scrollbar-width: thin;
  scrollbar-color: colors.$color-list-view-scroll-bar
    colors.$scrollbar-track-background-color;

  & li {
    padding: 3px 0;
  }

  & li > div {
    text-align: left;
  }
}

.reversed {
  color: colors.$color-list-view-stt;
}

.stt-text {
  hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  overflow-wrap: break-word;
}

.translated {
  hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  overflow-wrap: break-word;
}

// 暫定テキストスクロールバーの設定
.raw::-webkit-scrollbar {
  display: none;
}

// 翻訳テキストスクロールバーの設定
.recognized::-webkit-scrollbar {
  width: styles.$scrollbar-width;
}

.recognized::-webkit-scrollbar-track {
  background: colors.$scrollbar-track-background-color;
}

.recognized::-webkit-scrollbar-thumb {
  background-color: colors.$color-list-view-scroll-bar;
  border-radius: styles.$scrollbar-thumb-radius;
}
