@use '@/styles/colors.module' as colors;
@use '@/styles/palettes.module' as palettes;
@use '@/styles/styles.module' as styles;

.toggle-button-wrapper {
  & input {
    display: none;
  }
}

.toggle-button {
  display: block;
  position: relative;
  z-index: 300;
  width: 51px;
  height: 31px;
  border-radius: 20px;
  background-color: palettes.$palette-light-gray;
  cursor: pointer;
  transition: background-color 0.4s;
}

input[type='checkbox']:checked {
  & + .toggle-button {
    background-color: palettes.$palette-pacific-blue;

    &::after {
      left: 22px;
    }
  }
}

input[type='checkbox']:disabled {
  & + .toggle-button {
    opacity: styles.$toggle-disabled-opacity;
  }
}

.toggle-button::after {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  box-shadow: 0 0 5px rgb(0 0 0 / 20%);
  background-color: palettes.$palette-true-white;
  content: '';
  transition: left 0.4s;
}
