// === 全般設定 ===
// 横幅最大値(mobile)
$mobile-max-width: 767px;

// === ヘッダー ===
// ヘッダーの高さ
$header-height: 62px;
// ヘッダー内のボタンの角丸
$header-button-radius: 5px;

// === フッター===
// フッター自体の高さ
$footer-host-height: 110px;
$footer-guest-height: 60px;
// ホストのフッター自体の高さ(セーフエリア含む)
$footer-host-safe-area-height: calc(
  $footer-host-height + env(safe-area-inset-bottom)
);
// ゲスト画面のフッターの高さ
$footer-guest-safe-area-height: calc(
  $footer-guest-height + env(safe-area-inset-bottom)
);
// フッター開閉ボタンの表示エリアの高さ
$footer-button-height: 45px;
// フッター表示領域の横幅の最大値(mobile)
$footer-mobile-max-width: 350px;
// フッター表示領域の横幅の最小値(mobile)
$footer-mobile-min-width: 300px;
// フッター内のボタンの文字サイズ
$footer-button-font-size: 10px;

// === フォントサイズ ===
// タイトル
$font-size-title: 1.75rem;
// 見出し
$font-size-heading: 1.3125rem;
// 本文
$font-size-main: 0.875rem;
// ボタン
$font-size-button: 1.0625rem;
// リンク
$font-size-link: 1rem;
// ダイアログ　タイトル
$font-size-dialog-title: 1.25rem;
// 注意書き
$font-size-caution: 0.75rem;
// 注意書き 行間
$font-size-caution-line-height: 1;
// システム言語ダイアログのフォントサイズ
$font-size-language-dialog: 15px;
// 暫定テキストと確定テキストのデフォルトの文字サイズ
$font-size-recognized-default-text: 1rem;
// テキストブロックのサイズ（ミディアム）
$font-size-text-block-md: 0.9375rem;
// テキストブロックのサイズ（ラージ）
$font-size-text-block-lg: 1.25rem;
// テキストブロックのサイズ（Xラージ）
$font-size-text-block-xl: 1.75rem;
// フォーム内の文字サイズ
$font-size-form-input: 0.8125rem;
// トーストメッセージ内の文字サイズ
$font-size-toast-message: 1rem;

// === 行間 ===
// 見出し
$line-height-heading: 1.5rem;
// 本文
$line-height-main: 1.125rem;
// ボタン
$button-line-height: 1.5rem;

// === 利用可能残時間バナー ===
// バナーの高さ
$free-plan-banner-height: 40px;

// === スクロールバー ===
// スクロールバーの幅
$scrollbar-width: 5px;
// スクロールバーの丸み
$scrollbar-thumb-radius: 6px;

// === トグル ===
// ラベルの文字サイズ
$toggle-label-font-size: 16px;
// ラベルの行間
$toggle-label-line-height: 1.5;
// Disabled時の透明度
$toggle-disabled-opacity: 0.5;
// === バナー ===
// バナーの高さ
$banner-height: 40px;

// ==== カンファレンス画面 ====
// ボタンがDisabled時の透明度
$opacity-conference-button: 0.3;
// リストがDisabled時の透明度
$opacity-conference-select: 0.3;

// ==== メッセージオーバーレイ ====
$message-overlay-text-font-size: 1.75rem;

// === ダイアログ ===
// 通常時のモーダルダイアログの最大幅
$modal-dialog-normal-width: 400px;
// ダイアログのコンテンツ内の最大の高さ（スクロール表示用）
$modal-dialog-content-max-height: calc(100dvh - 200px);
// 言語選択ダイアログの文字サイズ
$modal-dialog-language-select-font-size: 1rem;

// === 共有機能 ===
// 共有ダイアログの最大幅
$modal-dialog-share-width: 480px;
// 共有ダイアログ オプションタイトルの文字サイズ
$share-dialog-title-size: 16px;
// 共有ダイアログ 説明文タイトルの文字サイズ
$share-dialog-descrption-size: 14px;
// 共有ダイアログ 説明文詳細の文字サイズ
$share-dialog-details-size: 12px;
// 共有ダイアログ 共通の行の高さ
$share-dialog-line-height: 16px;
// 共有ダイアログのボタン内の文字サイズ
$share-dialog-common-button-font-size: 0.875rem;
// 共有ダイアログのコピーボタンの文字サイズ
$share-dialog-copy-button-font-size: 0.8125rem;
// 共有ダイアログのスクリーンの高さの最大値(これ以上小さいとPCだと小さすぎる)
$share-dialog-max-screen-height: 863px;
// ヘッダーの共有関連ボタンの文字サイズ
$share-header-button-font-size: 0.8125rem;
// ヘッダーの共有関連ボタンの行の高さ
$share-header-button-line-height: 16px;

// === ゲスト画面 ===
// キャンペーンダイアログのタイトル部分
$guest-campaign-dialog-title: 1.2rem;
// スマホ用：キャンペーンダイアログのタイトル部分
$guest-campaign-dialog-mobile-title: 1rem;
// キャンペーンダイアログのメッセージ部分
$guest-campaign-dialog-message: 15px;

// === QRコード ===
// 通常サイズ
$qr-code-size: 176px;
// モバイルサイズ
$qr-code-mobile-size: 172px;
// 角丸
$qr-code-border-radius: 10%;
// ==== オーディオ設定 ====
// タイトルなどの大きめな文字サイズ
$setting-audio-title-font-size: 14px;
// 説明文などの小さめな文字サイズ
$setting-audio-description-font-size: 12px;
