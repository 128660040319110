@use '@/styles/_palettes.module.scss' as palettes;
@use '@/styles/_styles.module.scss' as styles;
@use '@/styles/_colors.module.scss' as colors;

.free-plan-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.free-plan-button {
  display: flex;
  height: 100%;

  padding: 0px 20px 0px 20px;
  background: colors.$color-footer-free-plan-button;
  gap: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  overflow: hidden;
  &:hover {
    cursor: pointer;
    filter: brightness(1.2);
  }

  &:active {
    background-color: palettes.$palette-shark;
  }
}

.free-plan-label {
  display: flex;
  gap: 15px;
}

.forward-icon {
  display: block;
  width: 15px;
  height: 24px;
  background: palettes.$palette-true-white;
  mask: url('../../../../assets/forward.svg') no-repeat center center / contain;
  -webkit-mask: url('../../../../assets/forward.svg') no-repeat center center /
    contain;
}
