@use '../../../styles/palettes.module' as palettes;
@use '../../../styles/colors.module' as colors;
@use '../../../styles/mixins.module' as mixins;

.plan-container {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  align-items: stretch;
}

.remaining-time {
  @include mixins.default-font-size;
  font-weight: 600;
  line-height: 1.294;
}

.license-exp {
  margin-top: 10px;
}

.trial-plan-notice {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  & > p {
    margin: 0;
  }
}

.purchase-license {
  & > button {
    width: 100%;
    min-width: 240px;
    padding: 12px 20px;
    border-radius: 5px;
    border: none;

    font-weight: 700;
    line-height: 1.412;

    &:hover {
      cursor: pointer;
    }
  }
}

.serial-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  align-self: stretch;

  & .serial {
    width: 100%;

    & > p {
      text-align: center;
      line-height: 1.385;
      margin: 0 0 4px 0;
    }

    & > div {
      & > input {
        border-radius: 4px;
        border: none;
        background: colors.$color-modal-dialog-bg;

        &:disabled {
          -webkit-text-fill-color: palettes.$palette-true-white;
          opacity: 1;
        }
      }
    }
  }

  .account-info {
    width: 100%;
    & button {
      display: flex;
      width: 100%;
      min-width: 240px;
      padding: 12px 20px;
      justify-content: center;
      align-items: center;
      align-self: stretch;

      border-radius: 5px;
      border: 1px solid colors.$color-modal-dialog-button-label;
      line-height: 1.41;
      font-weight: 700;
      color: colors.$color-modal-dialog-button-label;
      background: colors.$color-modal-dialog-bg;

      &:hover {
        cursor: pointer;
        background-color: colors.$color-modal-popup-bg-hover;
      }

      &:active {
        background-color: colors.$color-modal-popup-bg-active;
      }
    }
  }
}
