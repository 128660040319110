@use '@/styles/_palettes.module.scss' as palettes;
@use '@/styles/_styles.module.scss' as styles;
@use '@/styles/_colors.module.scss' as colors;

.campaign {
  z-index: 2000;
  background-color: palettes.$palette-dark-charcoal;
  height: styles.$free-plan-banner-height;
  font-size: styles.$font-size-main;
  position: fixed;
  top: 8px;
  right: 12px;
  border-radius: 20px;

  & div {
    color: palettes.$palette-true-white;
  }

  &:hover {
    cursor: pointer;
    filter: brightness(1.2);
  }

  &:active {
    background-color: palettes.$palette-shark;
  }
}

.campaign-button {
  display: flex;
  padding: 0px 12px 0px 5px;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  background: transparent;
  border: none;
}

.campaign-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: styles.$banner-height;

  @media screen and (max-width: styles.$mobile-max-width) {
    // スマホ用
    & p {
      margin: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 110px;
    }
  }
}

.campaign-icon {
  margin: 0 8px 3px 8px;
  display: flex;
  justify-content: flex-end;
  width: 24px;
  height: 28px;
  background: palettes.$palette-true-white;
  mask: url('../../../assets/share-guest-campaign.svg') no-repeat center center /
    contain;
  -webkit-mask: url('../../../assets/share-guest-campaign.svg') no-repeat center
    center / contain;
}

.forward-icon {
  margin: 0 0px 2px 6px;
  display: flex;
  justify-content: flex-end;
  width: 15px;
  height: 24px;
  background: palettes.$palette-true-white;
  mask: url('../../../assets/forward.svg') no-repeat center center / contain;
  -webkit-mask: url('../../../assets/forward.svg') no-repeat center center /
    contain;
}
