@use '@/styles/_palettes.module.scss' as palettes;
@use '@/styles/_styles.module.scss' as styles;
@use '@/styles/_colors.module.scss' as colors;

.share {
  height: 36px;
  font-size: styles.$share-header-button-font-size;
  position: flex;

  & div {
    color: palettes.$palette-true-white;
  }
}

.share-button {
  border-radius: styles.$header-button-radius;
  border: 1px solid var(--border-destructive, palettes.$palette-true-white);
  background-color: palettes.$palette-true-black;
  display: flex;
  padding: 6px 12px;
  gap: 8px;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &:hover {
    cursor: pointer;
    filter: brightness(1.2);
    background-color: palettes.$palette-shark;
  }

  &:active {
    background-color: palettes.$palette-tundora;
  }
}

.share-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: styles.$share-header-button-font-size;
}

.share-icon {
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  background: palettes.$palette-true-white;
  mask: url('../../../../assets/screen_share.svg') no-repeat center center /
    contain;
  -webkit-mask: url('../../../../assets/screen_share.svg') no-repeat center
    center / contain;
}
