@use './palettes.module' as palettes;

// オーバーレイの背景色
$color-default-overlay-bg: palettes.$palette-true-black;
// ダイアログ関連の基本背景色
$color-default-container-bg: palettes.$palette-true-white;

// === ヘッダー ===
$color-header: palettes.$palette-true-black;
$color-header-border: palettes.$palette-pacific-blue;
$color-header-slider-thumb: rgba(palettes.$palette-true-black, 0.4);
$color-header-back-button: palettes.$palette-true-white;
$color-header-back-button-hover: palettes.$palette-dodger-blue;
$color-header-back-button-active: palettes.$palette-pacific-blue;
// 言語選択エリア
$color-language-area: palettes.$palette-medium-gray;

// === フッター ===
// 背景色
$color-footer-bg: rgba(palettes.$palette-cod-gray, 0.9);
// 無料お試しボタン色
$color-footer-free-plan-button: palettes.$palette-medium-gray;
// ボタンアニメーション STT ON　影
$color-footer-btn-shadow: rgba(palettes.$palette-true-white, 0.5);
// ボタンアニメーション0% STT ON
$color-footer-btn-animation-stt-on: rgba(palettes.$palette-true-white, 0.4);
// ボタンアニメーション100% STT ON
$color-footer-btn-animation-pulsate-stt-on: rgba(
  palettes.$palette-true-white,
  0.5
);
// 音声出力デバイスリスト 文字色
$color-footer-device-list-text: palettes.$palette-nobel;

// === メニューボタン ===
$color-menu: palettes.$palette-true-white;
$color-menu-hover: palettes.$palette-dodger-blue;
$color-menu-active: palettes.$palette-pacific-blue;

// === フォントサイズボタン ===
$color-font-size: palettes.$palette-true-white;
$color-font-size-hover: palettes.$palette-dodger-blue;
$color-font-size-active: palettes.$palette-pacific-blue;

// === TTSボタン ===
$color-tts-play-button: palettes.$palette-true-white;
$color-tts-play-button-hover: palettes.$palette-dodger-blue;
$color-tts-play-button-active: palettes.$palette-pacific-blue;
$color-tts-stop-button: palettes.$palette-true-white;
$color-tts-stop-button-hover: palettes.$palette-dodger-blue;
$color-tts-stop-button-active: palettes.$palette-pacific-blue;

// === ボタン ===
// 青ボタン(活性)の色
$color-blue-button: palettes.$palette-pacific-blue;
// 青ボタン(アクティブ)の色
$color-blue-active: palettes.$palette-cerulean;
// 青ボタン(非活性)の色
$color-blue-button-disabled: rgba(palettes.$palette-true-white, 0.15);
// 青ボタン(非活性)の文字色
$color-blue-button-disabled-label: rgba(palettes.$palette-true-white, 0.2);

// === コンテンツ全般 ===
$color-contents-bg: palettes.$palette-true-black;

// === ポップアップ ===
// 背景色
$color-modal-popup-bg: rgba(palettes.$palette-blue-shark, 0.9);
// 背景色(ホバー時)
$color-modal-popup-bg-hover: rgba(palettes.$palette-true-black, 0.2);
// 背景色(アクティブ時)
$color-modal-popup-bg-active: palettes.$palette-bright-cyan-blue;
// 文字色
$color-modal-popup-text: palettes.$palette-true-white;
// 枠線の色
$color-modal-popup-border: rgba(palettes.$palette-true-white, 0.16);

// ==== ダイアログ ====
// ボタンの色
$color-modal-dialog-button: palettes.$palette-blue-shark;
// ボタンの文字色
$color-modal-dialog-button-label: palettes.$palette-pacific-blue;
// Infoボタンの文字色
$color-modal-dialog-info-button-label: palettes.$palette-pacific-blue;
// Warningボタンの文字色
$color-modal-dialog-warning-button-label: palettes.$palette-coral-red;
// キャンセルボタンの文字色
$color-modal-dialog-chancel-button-label: palettes.$palette-pacific-blue;
// 文字色
$color-modal-dialog-text: palettes.$palette-true-white;
// 縁のみの文字色
$color-modal-dialog-bordered-text: palettes.$palette-dodger-blue;
// 背景色
$color-modal-dialog-bg: palettes.$palette-eerie-black;
// 線の色
$color-modal-dialog-border: rgba(palettes.$palette-true-white, 0.16);
// ダイアログの影色
$color-modal-dialog-shadow: palettes.$palette-boulder;
// ダイアログの枠線
$color-modal-dialog-frame-border: rgba(palettes.$palette-true-white, 0.04);
// 閉じるボタンホバー時の背景色
$color-modal-close-button-hover: rgba(palettes.$palette-true-black, 0.12);
// プルダウンdisabled時に表示するテキストの文字色
$color-modal-dialog-disable: rgba(palettes.$palette-true-white, 0.6);
// プランダイアログのシリアル表示欄の背景色
$color-modal-serial-input: rgba(palettes.$palette-true-black, 0.08);
// 通訳履歴ダイアログ ダウンロードボタン disabled時の背景色
$color-modal-history-download-disable: rgba(palettes.$palette-true-white, 0.08);
// 言語選択ダイアログ セレクトボックス背景色
$color-modal-lang-select: palettes.$palette-dark-charcoal;

// ==== Spinner ====
// スピナーの色
$color-spinner-top: palettes.$palette-dark-dodger-blue;
$color-spinner: palettes.$palette-mercury;

// ==== Button ====
// ボタンの色
$color-button: palettes.$palette-pacific-blue;
// ボタンの枠線の色
$color-button-border: palettes.$palette-pacific-blue;
// ボタンの文字色
$color-button-label: palettes.$palette-true-white;
// ボタンの色(ホバー時)
$color-button-hover: palettes.$palette-cyan-blue;
// ボタンの色(アクティブ時)
$color-button-active: palettes.$palette-cerulean;
// キャンセルボタン
$color-button-cancel: palettes.$palette-dark-charcoal;
// ==== デンジャーボタン ====
// 枠線の色
$color-destactive-button-border: palettes.$palette-coral-red;
// 背景がグレーの時の色（ホバー）
$color-destactive-button-hover-gray: rgba(palettes.$palette-coral-red, 0.08);
// 背景がグレーの時の色（アクティブ）
$color-destactive-button-active-gray: rgba(palettes.$palette-coral-red, 0.16);
// デンジャーボタンの色（ホバー）
$color-destactive-button-hover-black: rgba(palettes.$palette-coral-red, 0.2);
// デンジャーボタンの色（アクティブ）
$color-destactive-button-active-black: rgba(palettes.$palette-coral-red, 0.28);

// ==== Select ====
// セレクトボックスの文字色
$color-select-letter: palettes.$palette-sky-blue;

// ==== STT暫定テキスト ====
// STT暫定テキストの文字色
$color-list-view-interim-ttt-letter: palettes.$palette-pacific-blue;
$color-list-view-interim-stt-letter: palettes.$palette-chathams-blue;
// スクロールバーのつまみの色
$color-list-view-scroll-bar: palettes.$palette-light-gray;
// スクロールバーの背景色
$scrollbar-track-background-color: rgba(palettes.$palette-true-black, 0.5);

// ==== STT 確定テキスト ====
$color-list-view-stt: palettes.$palette-nobel;

// ==== Toast ====
// 背景色
$color-toast-bg: rgba(palettes.$palette-blue-shark, 0.9);
// 文字色
$color-toast-text: palettes.$palette-true-white;

// ==== ようこそ画面 ====
// 背景色
$color-welcome-bg: palettes.$palette-gray;

// ==== 利用規約画面 ====
// 利用規約リンクの文字色
$color-agreement-link: palettes.$palette-pacific-blue;
// 利用規約リンクの文字色(ホバー時)
$color-agreement-link-hover: palettes.$palette-dark-dodger-blue;
// 利用規約リンクの文字色(アクティブ時)
$color-agreement-link-active: palettes.$palette-cerulean;
// 同意しないボタンの線の色
$color-disagree-button-border: palettes.$palette-pacific-blue;
// 同意しないボタンの文字色
$color-disagree-button-label: palettes.$palette-pacific-blue;
// 同意しないボタンのホバー時の色
$color-disagree-button-hover: rgba(palettes.$palette-azure-radiance, 0.08);
// 同意しないボタンのアクティブ時の色
$color-disagree-button-active: rgba(palettes.$palette-azure-radiance, 0.16);

// ==== ホーム画面 ====
// 選択ボタンの説明の文字色
$color-home-button-explanation-text: palettes.$palette-silver-gray;
// ボタン色
$color-home-button: palettes.$palette-eerie-black;
// ボタン色_push時
$color-home-button-push: palettes.$palette-very-dark-grayish-blue;

// ==== 共有機能 ====
// リンクコピーブロックの背景色
$color-copy-link-background: rgba(palettes.$palette-true-white, 0.12);
// リンクコピーボタンの文字色
$color-copy-link: palettes.$palette-pacific-blue;
// リンクコピーボタンの文字色(ホバー時)
$color-copy-link-hover: palettes.$palette-dark-dodger-blue;
// リンクコピーボタンの文字色(アクティブ時)
$color-copy-link-active: palettes.$palette-cerulean;

// ==== メッセージオーバーレイ ====
// 文字色
$color-message-overlay-text: palettes.$palette-true-white;

// === 共有ダイアログ ===
// 共有ダイアログのデンジャーゾン背景色
$color-share-dialog-danger-zone: palettes.$palette-cocoa-brown;

// ==== オーディオ設定 ====
// disabled時の色
$color-disabled-label: palettes.$palette-strong-gray;
$color-setting-audio: palettes.$palette-true-white;
$color-setting-audio-hover: palettes.$palette-dodger-blue;
$color-setting-audio-active: palettes.$palette-pacific-blue;
