@use '../../../styles/mixins.module' as mixins;

.details-container {
  position: relative;
}

.popup-container {
  display: block;

  summary {
    &::-webkit-details-marker {
      display: none;
    }
    list-style: none;
    display: block;
    padding: 0;
  }
  &.top {
    transform: scaleY(-1);
    position: relative;
    z-index: 100;
    > summary {
      position: relative;
      transform: scaleY(-1);
    }
  }

  + .close-button {
    @include mixins.reset-button-style;
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    cursor: default;
    content: ' ';
    background: transparent;
  }

  &[open] {
    > summary::before {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 80;
      display: block;
      cursor: default;
      content: ' ';
      background: transparent;
    }

    + .close-button {
      display: block;
    }
  }
}

.popup-contents {
  position: absolute;
  display: block;
  padding: 0;
  z-index: 100;
  width: max-content;

  &.right {
    right: 0;
  }
  &.left {
    left: 0;
  }
  &.left-top {
    right: 6px;
    bottom: 40px;
  }

  &.top {
    transform: scaleY(-1);
  }
}
