@use '../../../styles/palettes.module' as palettes;
@use '../../../styles/colors.module' as colors;
@use '../../../styles/styles.module' as styles;

.footer {
  width: 100%;
  color: palettes.$palette-true-white;
  background: linear-gradient(
    180deg,
    transparent 0%,
    transparent 35%,
    colors.$color-footer-bg 35%,
    colors.$color-footer-bg 100%
  );
}

.footer-open {
  height: styles.$footer-host-height;
  transition: bottom 0.6s;
  bottom: 0;
  position: fixed;
  width: calc(100vw - (env(safe-area-inset-left) + env(safe-area-inset-right)));
}

.footer-close {
  overflow: hidden;
  transition: bottom 0.3s;
  bottom: calc(-1 * styles.$footer-host-height - env(safe-area-inset-bottom));
  position: fixed;
  width: calc(100vw - (env(safe-area-inset-left) + env(safe-area-inset-right)));
}

.footer-container {
  height: styles.$footer-host-safe-area-height;
  display: flex;
  justify-content: center;
}

.translation-footer {
  margin: 14px auto;
  max-width: calc(100% - 40px);
  width: 100%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: styles.$mobile-max-width) {
    // スマホ用 フッターの横幅設定
    min-width: 300px;
    max-width: 350px;
    margin: 14px 30px calc(10px + env(safe-area-inset-bottom)) 30px;
  }
}

.translation-button {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  width: 100%;
  justify-content: flex-start;
  gap: 8px;
}

.footer-translation-container {
  display: flex;
  height: 100%;
}

.footer-container-left {
  flex: 1;
  justify-content: flex-end;
}

.footer-container-left > p:last-of-type {
  margin-left: auto;
}

.footer-container-right {
  flex: 1;
  justify-content: flex-start;
  align-items: flex-end;
  display: flex;
}

.footer-container-bottom {
  margin: 1rem 0 1.5rem 0;
}

.footer-container-center {
  padding: 0 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
}

.device-name {
  margin: 0;
  width: 100%;
  overflow: hidden;

  display: flex;
  align-items: flex-end;

  & p {
    margin: 0 0 env(safe-area-inset-bottom) 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.5;
    display: flex;

    & select {
      padding-left: 5px;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      color: palettes.$palette-true-white;
      outline: none;
      border: none;
      background-color: transparent;

      &:hover {
        cursor: pointer;
      }
    }

    & option {
      background: colors.$color-footer-bg;
    }
  }
}

button {
  background: transparent;
  border: none;
}

.img-btn {
  width: 64px;
  height: 64px;
  position: fixed;
  padding: 0;

  &:hover {
    cursor: pointer;
    filter: brightness(1.2);
  }
}

.footer-close-container {
  position: relative;
}

.footer-close-btn-display {
  bottom: calc(12px + env(safe-area-inset-bottom));
  right: 20px;
  position: fixed;
  transition: bottom 0.3s;
}

.footer-close-btn-hidden {
  bottom: calc(-24px - env(safe-area-inset-bottom));
  right: 20px;
  position: fixed;
  transition: bottom 0.3s;
}

.footer-close-stt {
  width: 24px;
  height: 24px;
  padding: 0;

  &:hover {
    cursor: pointer;
    filter: brightness(1.2);
  }

  &:active {
    pointer-events: none;
  }
}

.footer-close-ripple-btn-on::before,
.footer-close-ripple-btn-on::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  box-sizing: border-box;
  pointer-events: none;
  animation: pulsate 1.8s linear infinite;
  border: 4px solid colors.$color-footer-btn-animation-stt-on;
  box-shadow: 0 0 12px colors.$color-footer-btn-shadow;
}

.talk-on {
  background-image: url('../../../assets/talk_on.svg');

  &:active {
    background-image: url('../../../assets/talk_on_push.svg');
  }
}

.talk-off {
  background-image: url('../../../assets/talk_off.svg');

  &:active {
    background-image: url('../../../assets/talk_off_push.svg');
  }
}

.ripple-btn-on {
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 24px colors.$color-footer-btn-shadow;
  cursor: pointer;
}

.ripple-btn-on::before,
.ripple-btn-on::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  border: 8px solid colors.$color-footer-btn-animation-stt-on;
  border-radius: 50%;
  box-sizing: border-box;
  pointer-events: none;
  animation: pulsate 2.8s linear infinite;
}

.ripple-btn-on::after {
  animation-delay: 1.4s;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.7);
    opacity: 0;
    border: 1px solid colors.$color-footer-btn-animation-pulsate-stt-on;
    filter: brightness(70%);
  }
}

.free-plan-button {
  text-align: left;
  color: palettes.$palette-true-white;
  font-size: styles.$font-size-main;
  justify-content: flex-start;
  position: absolute;
  left: 20px;

  & p {
    margin: 0;
    padding: 0;
    color: palettes.$palette-true-white;
  }
}

.free-plan-button-footer-close {
  z-index: 300;
  bottom: calc(12px + env(safe-area-inset-bottom));
  left: 20px;
  position: fixed;
}

@media screen and (max-width: 1023px) {
  // 横幅1023px以下の場合は以下を適用
  .free-plan-button-footer-open {
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
    bottom: calc(110px + env(safe-area-inset-bottom));
    position: fixed;
  }
}
