@use '@/styles/mixins.module' as mixins;
@use '../../../styles/colors.module' as colors;
@use '@/styles/styles.module' as styles;

// ホスト画面用のスタイル
.message-layer-outer-host {
  @include mixins.message-overlay-style(
    styles.$header-height,
    calc(100% - styles.$header-height - styles.$footer-host-height)
  );
}

// ゲスト画面用のスタイル
.message-layer-outer-guest {
  @include mixins.message-overlay-style(
    styles.$header-height,
    calc(100% - styles.$header-height - styles.$footer-guest-height)
  );
}

.message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: colors.$color-message-overlay-text;
  font-size: styles.$message-overlay-text-font-size;
  width: 85%;
  text-align: center;
}
