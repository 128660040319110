@use '@/styles/palettes.module' as palettes;
@use '@/styles/colors.module' as colors;
@use '@/styles/styles.module' as styles;
@use '@/styles/mixins.module' as mixins;

ul.share-guest-menu-list {
  list-style: none;
  padding: 0;
  margin: 0;

  & li {
    line-height: 1.4;

    & select:only-child {
      padding: 12px 40px 12px 64px;

      &:not(:disabled):hover {
        cursor: pointer;
        background-color: colors.$color-modal-popup-bg-hover;
      }
    }

    & span:not(:only-child) > select {
      padding: 12px 40px 0 59px;
    }

    & select {
      appearance: none;
      -webkit-appearance: none;
      color: palettes.$palette-true-white;
      outline: none;
      border: none;
      background-color: transparent;
      text-overflow: ellipsis;
      width: 100%;
    }

    & p {
      margin: 0;
      padding: 0 40px 12px 59px;
      font-size: styles.$font-size-caution;
      line-height: styles.$font-size-caution-line-height;
      color: colors.$color-modal-dialog-disable;
    }

    & a,
    button {
      text-decoration: none;
      display: flex;
      color: palettes.$palette-true-white;
      padding: 12px 20px;
      text-align: left;
      align-items: center;
      gap: 14px;

      &:hover {
        background-color: colors.$color-modal-popup-bg-hover;
      }
    }

    & button {
      width: 100%;
    }

    & option {
      background: colors.$color-modal-popup-bg;
    }
  }
}

.share-guest-menu {
  background: none;
  display: flex;
  justify-content: flex-end;
  border: 0;
  line-height: 1;
  width: 30px;
  padding: 5px;

  & div {
    width: 44px;
    height: 30px;
  }

  & .share-guest-menu-icon {
    mask: url('../../../../assets/menu.svg') no-repeat center center / contain;
    -webkit-mask: url('../../../../assets/menu.svg') no-repeat center center /
      contain;
    background: colors.$color-menu;
  }

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      & .share-guest-menu-icon {
        background: colors.$color-menu-hover;
      }
    }
  }

  &:active {
    & .share-guest-menu-icon {
      background: colors.$color-menu-active;
    }
  }
}

.share-guest-menu-contents {
  @include mixins.popup-details-contents;
  & button {
    background: transparent;
    border: none;
  }
}

// ドロップダウンのアイコン表示のための設定
.pull-down-icon {
  position: relative;

  & > select:disabled {
    opacity: 0.7;
  }
}
