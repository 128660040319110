@use '@/styles/colors.module' as colors;
@use '@/styles/styles.module' as styles;

.open-link {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  color: colors.$color-agreement-link;
  font-size: styles.$font-size-link;
  line-height: 1.3rem;

  &:hover {
    color: colors.$color-agreement-link-hover;
  }

  &:active {
    color: colors.$color-agreement-link-active;
  }

  & > span {
    display: flex;
  }

  > ::after {
    position: relative;
    content: '';
    mask: url('../../../assets/open_in_new.svg') no-repeat center center /
      contain;
    -webkit-mask: url('../../../assets/open_in_new.svg') no-repeat center center /
      contain;
    padding-left: 2px;
    bottom: 2px;
    background: colors.$color-agreement-link;
    width: 24px;
    height: 24px;
    display: block;
  }

  > :hover::after {
    background: colors.$color-agreement-link-hover;
  }

  > :active::after {
    background: colors.$color-agreement-link-active;
  }
}
