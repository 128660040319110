@use '@/styles/colors.module' as colors;
@use '@/styles/styles.module' as styles;

.content-layout {
  flex-grow: 1;
  overflow-y: hidden;

  scrollbar-width: none;
  background: colors.$color-contents-bg;
}
