@use '../../../styles/palettes.module' as palettes;
@use '@/styles/mixins.module' as mixins;
@use '@/styles/styles.module' as styles;

.form-input {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  align-self: stretch;
  width: 100%;
  min-width: 165px;
  flex: 0 0 0%;
  border-radius: 0.25rem;
  border-width: 1px;
  border-style: solid;
  padding: 0.75rem 0.5rem;
  @include mixins.default-font-size;
  text-align: center;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: palettes.$palette-shark;
  color: palettes.$palette-true-white;
}

.form-input-border-red {
  border-color: palettes.$palette-true-red;
  background: palettes.$palette-scorpion;
  color: palettes.$palette-coral-red;
}

.form-input:-webkit-autofill {
  box-shadow: 0 0 0 1000px palettes.$palette-shark inset;
  -webkit-text-fill-color: palettes.$palette-true-white;
}

.form-input-border-red:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  box-shadow: 0px 0px 1px palettes.$palette-true-red;
}

.form-input-border-red:-webkit-autofill {
  box-shadow: 0 0 0 1000px palettes.$palette-scorpion inset;
  -webkit-text-fill-color: palettes.$palette-coral-red;
}

.form-input-border-gray {
  border-color: palettes.$palette-boulder;
}

.form-input-error-message {
  text-align: left;
  padding-left: 0.5rem;
  font-size: styles.$font-size-form-input;
  color: palettes.$palette-coral-red;
}
