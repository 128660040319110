@use '@/styles/colors.module' as colors;
@use '@/styles/palettes.module' as palettes;
@use '@/styles/styles.module' as styles;
@use '@/styles/mixins.module' as mixins;

.selectusage {
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 80px 30px;
  align-items: center;
}

.selectusage-title {
  @include mixins.common_contents_title_style;
}

.selectusage-contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  min-width: 330px;
  @media screen and (max-width: styles.$mobile-max-width) {
    // スマホ用
    min-width: 240px;
  }

  @include mixins.common_blue_button_style;
}
