@use '@/styles/colors.module' as colors;
@use '@/styles/styles.module' as styles;

// 下部固定用暫定テキスト
.interim-fixed-footer {
  padding-top: 5px;
  min-height: 28px;
  &:empty {
    min-height: 28px;
  }
  font-size: styles.$font-size-recognized-default-text;
  display: flex;
  flex-direction: row;
  text-align: left;
  width: 100%;
  white-space: nowrap;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: colors.$scrollbar-track-background-color
    colors.$scrollbar-track-background-color;
  color: colors.$color-list-view-interim-ttt-letter;
}

.interim-fixed-content {
  width: 45%;
  margin: 0 10px 0 10px;
}
