.select {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  option {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
