@use '@/styles/colors.module' as colors;
@use '@/styles/palettes.module' as palettes;

.input-range {
  transform: rotate(-360deg);
  transform-origin: top right;
  touch-action: pan-down;
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  background: rgba(palettes.$palette-true-black, 0.32);
  height: 4px;
  width: 232px;
  border-radius: 2px;
  outline: 0;

  &::-webkit-slider-thumb {
    appearance: none;
    background: palettes.$palette-true-white;
    width: 16px;
    height: 16px;
    border-radius: 50%;

    &:hover {
      border: solid 4px colors.$color-header-slider-thumb;
      width: 24px;
      height: 24px;
    }
  }
}
