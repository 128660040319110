@use '@/styles/colors.module' as colors;
@use '@/styles/styles.module' as styles;
@use '@/styles/mixins.module' as mixins;

header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;

  transition: top 0.3s;
}

.header-contents {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  gap: 20px;
  background-color: colors.$color-header;
  border-bottom: 4px solid colors.$color-header-border;
  box-sizing: border-box;
}

.header-left-contents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;

  .header-logo {
    display: flex;
    height: 40px;
    padding-left: 4px;

    & > div {
      padding-top: 4px;
    }
  }
}

.header-menu {
  display: flex;
}

.header-logo-img {
  max-width: 150px;
  height: 40px;
}

.back-btn {
  width: 24px;
  mask: url('../../../assets/back.svg') no-repeat center center / contain;
  -webkit-mask: url('../../../assets/back.svg') no-repeat center center /
    contain;
  background: colors.$color-header-back-button;

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      background: colors.$color-header-back-button-hover;
    }
  }

  &:active {
    background: colors.$color-header-back-button-active;
  }
}

.menu {
  background: none;
  border: 0;
  line-height: 1;
  width: 44px;

  & div {
    width: 44px;
    height: 24px;
  }

  & .main-menu {
    mask: url('../../../assets/menu.svg') no-repeat center center / contain;
    -webkit-mask: url('../../../assets/menu.svg') no-repeat center center /
      contain;
    background: colors.$color-menu;
  }

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      & .main-menu {
        background: colors.$color-menu-hover;
      }
    }
  }

  &:active {
    & .main-menu {
      background: colors.$color-menu-active;
    }
  }
}

.menu-center-contents {
  display: flex;
  min-width: 240px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.header-right-contents {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;

  .share-contents {
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.menu-contents {
  @include mixins.popup-details-contents;

  & button {
    width: 100%;
  }
}

.menu-display-none {
  display: none;
}

.menu-separate {
  display: flex;
  min-width: 160px;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  & div {
    width: 100%;
    height: 1px;
    background: rgba(colors.$color-modal-popup-border, 0.2);
  }
}

.tts-menu {
  display: flex;
  column-gap: 20px;
  align-items: center;

  & div {
    line-height: 17px;
  }
}

.tts-disabled-message {
  font-size: styles.$font-size-caution;
  line-height: styles.$font-size-caution-line-height;
  padding-left: 44px;
}
