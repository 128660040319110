@use '@/styles/colors.module' as colors;
@use '@/styles/palettes.module' as palettes;
@use '@/styles/styles.module' as styles;

.license-list-container {
  border-top: 1px solid colors.$color-modal-dialog-border;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.license-list {
  align-self: stretch;
  position: relative;
  background-color: #ebeced;
  height: 480px;
  overflow-y: auto;
  overflow-wrap: break-word;
  color: palettes.$palette-true-black;
  text-align: left;
}

.license-list-cell-container {
  box-sizing: border-box;
  padding-left: 8px;
  border-bottom: 2px solid #e6e6e6;
}

// スクロールバーの設定
.license-list::-webkit-scrollbar {
  width: styles.$scrollbar-width;
}

.license-list::-webkit-scrollbar-track {
  background: colors.$scrollbar-track-background-color;
}

.license-list::-webkit-scrollbar-thumb {
  background-color: colors.$color-list-view-scroll-bar;
  border-radius: styles.$scrollbar-thumb-radius;
}
