@use '@/styles/palettes.module' as palettes;
@use '@/styles/colors.module' as colors;
@use '@/styles/styles.module' as styles;
@use '@/styles/mixins.module' as mixins;

.history-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  & .leave-history {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    font-size: styles.$toggle-label-font-size;
    line-height: styles.$toggle-label-line-height;
  }

  & button {
    display: flex;
    min-width: 240px;
    width: 100%;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    border-radius: 5px;
    border: 1px solid colors.$color-modal-dialog-button-label;
    color: colors.$color-modal-dialog-button-label;
    background-color: colors.$color-modal-dialog-bg;

    font-weight: 700;
    line-height: styles.$button-line-height;

    &:hover {
      cursor: pointer;
      background-color: colors.$color-modal-close-button-hover;
    }

    &:active {
      background-color: colors.$color-disagree-button-active;
    }

    &:disabled {
      cursor: default;
      filter: saturate(0);
      background-color: colors.$color-modal-history-download-disable;
    }
  }
}
