@use '../../../styles/palettes.module' as palettes;
@use '@/styles/styles.module' as styles;

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.font-variant-primary {
  color: palettes.$palette-true-white;
}

.font-variant-alert {
  color: palettes.$palette-coral-red;
}

.font-size-md {
  font-size: styles.$font-size-text-block-md;
  font-weight: 400;
}

.font-size-lg {
  margin-bottom: 3px;
  font-size: styles.$font-size-text-block-lg;
  line-height: 1.75rem; /* 28px */
  font-weight: 600;
}

.font-size-xl {
  margin-bottom: 12px;
  font-size: styles.$font-size-text-block-xl;
  font-weight: 600;
}

.text-block {
  white-space: pre-line;
  overflow-wrap: break-word;
}
