@use '@/styles/_palettes.module.scss' as palettes;
@use '@/styles/_styles.module.scss' as styles;
@use '@/styles/_mixins.module.scss' as mixins;

.campaign-banner-container {
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: styles.$guest-campaign-dialog-message;
}

.campaign-banner-ribbon {
  width: 100%;
  height: 75px;
  background-image: url('../../../../assets/share-guest-ribbon.svg');
  background-size: 100%, 100%;
  background-repeat: no-repeat, no-repeat;
  background-position: center center;
  font-weight: 600;
  font-size: styles.$guest-campaign-dialog-title;
  color: palettes.$palette-true-black;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: styles.$mobile-max-width) {
  .campaign-banner-ribbon {
    font-size: styles.$guest-campaign-dialog-mobile-title;
    font-weight: 700;
  }
}

.select-mic-button-image {
  margin: 20px 0;

  & img {
    max-width: 250px;
  }
}

.campaign-banner-title {
  font-weight: 600;
  font-size: styles.$guest-campaign-dialog-title;
  line-height: 1.5rem;
}

.campaign-banner-button {
  @include mixins.common_blue_button_style;

  width: 100%;
  padding-top: 20px;
}
