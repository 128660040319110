@use '../../../styles/colors.module' as colors;
@use '@/styles/styles.module' as styles;

.link-button {
  display: inline-block;
  background-color: colors.$color-button;
  border: 5px solid colors.$color-button-border;
  color: colors.$color-button-label;
  border-radius: 18px;
  padding: 0.375rem 3rem;
  text-align: center;
  font-size: styles.$font-size-link;
  line-height: styles.$button-line-height;
  transition: transform 0.3s;
  text-decoration: none;
}

.disabled {
  pointer-events: none;
}
