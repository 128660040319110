@use './colors.module' as colors;
@use './styles.module' as styles;
@use './palettes.module' as palettes;

// デフォルトのフォントサイズ
@mixin default-font-size {
  font-size: 17px;
}

// flex
@mixin flex {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
}

// appearance: none
@mixin appearance-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

// ボタンのスタイルリセット用
@mixin reset-button-style {
  @include appearance-none();
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
}

// ダイアログのオーバレイのスタイル
@mixin dialog-overlay-style {
  background-color: rgba($color: colors.$color-default-overlay-bg, $alpha: 0.5);
  backdrop-filter: blur(4px);
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 3000;
}

// ダイアログ関連のスタイル
@mixin dialog-style {
  border-radius: 20px;
  background-color: colors.$color-default-container-bg;
}

// スクロール付きダイアログの共通スタイル
@mixin dialog-scroll-style {
  @media screen and (max-height: styles.$share-dialog-max-screen-height) {
    max-height: styles.$modal-dialog-content-max-height;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: colors.$color-list-view-scroll-bar
      colors.$scrollbar-track-background-color;
    -ms-overflow-style: none;
    margin-bottom: 14px;
  }
}

// モーダルダイアログの基本スタイル
@mixin modal-dialog-container-style($modal-dialog-max-width) {
  overflow: hidden;
  box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.16);
  background-clip: padding-box;
  background: transparent;
  background-color: colors.$color-modal-dialog-bg;
  user-select: none;
  margin: auto;
  border-radius: 14px;
  width: calc(100% - 40px);
  max-width: $modal-dialog-max-width;
  min-width: 320px;
  border: 1px solid colors.$color-modal-dialog-frame-border;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  $close-button-icon-size: 24px;
  $close-button-icon-top: 8px;
  .dialog-close-container {
    text-align: center;
  }

  .close-button {
    @include reset-button-style();
    cursor: pointer;
    height: calc(#{$close-button-icon-size} + #{$close-button-icon-top} * 2);
    width: calc(#{$close-button-icon-size} + #{$close-button-icon-top} * 2);
    border-radius: 50%;
    line-height: 0;
    padding: 8px;

    &:hover {
      background-color: colors.$color-modal-close-button-hover;
    }
  }

  // TODO: アイコン使うことになると思うので、色などのプロパティは削除する。
  .close-button-icon {
    display: block;
    position: relative;
    width: $close-button-icon-size;
    height: $close-button-icon-size;

    &::before,
    &::after {
      /* 共通設定 */
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 3px; /* 棒の幅（太さ） */
      height: $close-button-icon-size; /* 棒の高さ */
      background: palettes.$palette-mine-shaft;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

@mixin modal-dialog-header-style {
  @include flex();
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  text-align: center;

  p {
    font-size: styles.$font-size-dialog-title;
    font-weight: 700;
    line-height: 1.375rem;
    margin-block-start: 0.5rem;
    margin-block-end: 0.5rem;
    color: colors.$color-modal-dialog-text;

    &.mod-center {
      text-align: center;
    }
  }

  .header {
    width: 100%;
  }

  .left {
    width: 100%;
    padding-left: 40px;
  }

  &.mod-close-btn {
    padding-right: 10px;
  }
}

@mixin modal-dialog-content-style {
  @include flex();
  flex-direction: column;
  box-sizing: border-box;
  background-clip: padding-box;
  text-align: left;
  line-height: 20px;
  min-height: 0;
  color: colors.$color-modal-dialog-text;

  .content-primary {
    text-align: center;
    font-size: 13px;
    line-height: 18px;
  }
}

@mixin modal-dialog-buttons {
  width: 100%;
  @include flex();
  justify-content: space-between;
  box-sizing: border-box;
  margin: 0;
  border-top: 1px solid colors.$color-modal-dialog-border;

  &.mod-right {
    justify-content: flex-end;
  }

  .multi-buttons {
    max-width: 50%;
  }

  button {
    cursor: pointer;

    &.dialog-button {
      background: none;
      border-width: 0;
      padding: 12px 16px;
      color: colors.$color-modal-dialog-button-label;
      font-weight: 700;
      font-size: styles.$font-size-button;
      line-height: styles.$button-line-height;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;

      &:hover {
        background-color: colors.$color-modal-close-button-hover;
      }

      &:active {
        opacity: 0.2;
      }

      &:disabled {
        opacity: 0.3;
        cursor: default;
        pointer-events: none;
      }

      &.mod-horizontal {
        flex-grow: 1;
      }

      &.mod-bordered {
        border-right: 1px solid colors.$color-modal-dialog-border;
      }

      &.info {
        color: colors.$color-modal-dialog-info-button-label;
      }

      &.warning {
        color: colors.$color-modal-dialog-warning-button-label;
      }

      &.chancel {
        color: colors.$color-modal-dialog-chancel-button-label;
        font-style: normal;
        font-weight: 400;
      }

      & > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
// モーダルダイアログ内に表示するメッセージのスタイル
@mixin modal-dialog-message {
  padding: 0 0.75rem 20px 0.75rem;
}

// 共有ダイアログ内のコンテンツの共通スタイル
@mixin share-dialog-content-style {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  border-radius: 5px;
  border: 1px solid
    var(--border-secondary-on-surface, rgba(palettes.$palette-true-white, 0.32));
}

// ヘッダー内にある共有に関するボタンの共通スタイル
@mixin header-share-button-style {
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

// スピナーのオーバーレイのスタイル
@mixin spinner-overlay-style {
  background-color: rgba($color: colors.$color-default-overlay-bg, $alpha: 0.3);
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 20000;
}

// スピナーのスタイル
@mixin spinner-style($spinner-color, $spinner-top-color) {
  &,
  &::after {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
  z-index: 20000;
  position: absolute;
  top: 50%;
  left: 50%;
  border: 4px $spinner-color solid;
  border-top: 4px $spinner-top-color solid;
  animation: spin 1s infinite linear;

  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
}

// 青色のボタンの共通スタイル
@mixin common_blue_button_style {
  & button {
    background: colors.$color-blue-button;
    border: 0px;
    border-radius: 5px;
    padding: 12px 20px;
    width: 100%;
    min-width: 240px;

    &:disabled {
      cursor: default;
      background: colors.$color-blue-button-disabled;
      color: colors.$color-blue-button-disabled-label;
    }

    &:active {
      background: colors.$color-blue-active;
    }
  }
}

// 画面タイトルの共通スタイル
@mixin common_contents_title_style {
  display: flex;
  justify-content: center;

  & p {
    font-size: styles.$font-size-title;
    margin: 0;
  }
}

// details要素で作られたポップアップのスタイル
@mixin popup-details-contents {
  background-color: colors.$color-modal-popup-bg;
  border-radius: 14px;
  backdrop-filter: blur(50px);

  @media screen and (max-width: 480px) {
    // スマホ用 メニューの横幅
    max-width: 320px;
  }

  & ul {
    padding-left: 0;

    & li {
      list-style: none;

      &:first-child {
        border-radius: 14px 14px 0rem 0rem;
      }

      &:last-child {
        border-bottom: none;
        border-radius: 0rem 0rem 14px 14px;
      }

      & a,
      button {
        text-decoration: none;
        color: colors.$color-modal-popup-text;
        line-height: styles.$button-line-height;
        font-size: styles.$font-size-button;
        display: flex;
        align-items: center;
        text-align: left;
        column-gap: 20px;
        padding: 12px 20px;

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          cursor: pointer;
          background-color: colors.$color-modal-popup-bg-hover;
        }

        &:disabled {
          cursor: default;
          opacity: 0.3;
          background: palettes.$palette-true-black;
        }
      }
    }
  }
}

// メッセージオーバーレイのスタイル
@mixin message-overlay-style($message-overlay-top, $message-overlay-height) {
  background-color: rgba($color: colors.$color-default-overlay-bg, $alpha: 0.5);
  backdrop-filter: blur(2px);
  max-height: $message-overlay-height;
  width: 100%;
  position: fixed;
  left: 0;
  top: $message-overlay-top;
  bottom: 0;
  right: 0;
}
