@use '../../../styles/palettes.module' as palettes;
@use '../../../styles/styles.module' as styles;
@use '@/styles/mixins.module' as mixins;

.auth-error {
  display: flex;
  margin: 80px 30px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  gap: 60px;

  & > p {
    margin: 0;
    font-size: styles.$font-size-heading;
    font-style: normal;
    font-weight: 700;
    line-height: styles.$line-height-heading;
  }
}

.auth-error-contents {
  max-width: 420px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;

  & p {
    text-align: center;
    font-size: styles.$font-size-main;
    line-height: styles.$line-height-main;
    margin: 0;
  }

  // ボタン
  @include mixins.common_blue_button_style;
}
