@use '../../../styles/palettes.module' as palettes;
@use '../../../styles/colors.module' as colors;
@use '../../../styles/styles.module' as styles;
@use '../../../styles/mixins.module' as mixins;

.serial {
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 80px 30px;
}

.serial-title {
  @include mixins.common_contents_title_style;
}

form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
}

.serial-form {
  display: flex;
  flex-direction: column;
  gap: 60px;
  max-width: 400px;

  & > div:first-child {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  & p {
    text-align: center;
    font-size: styles.$font-size-main;
    line-height: styles.$line-height-main;
    margin: 0;
  }

  .serial-form-title {
    & p {
      text-align: left;
      font-size: styles.$font-size-main;
      margin: 2px;
    }
  }

  // ボタンの色と枠はページごとで違うため別途指定する
  .serial-button {
    @include mixins.common_blue_button_style;
  }

  .serial-form-error {
    & p {
      font-size: styles.$font-size-main;
      line-height: styles.$line-height-main;
    }
  }
}
