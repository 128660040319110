@use '@/styles/palettes.module' as palettes;
@use '@/styles/colors.module' as colors;
@use '@/styles/styles.module' as styles;
@use '@/styles/mixins.module' as mixins;

.setting-audio {
  background: none;
  border: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  color: colors.$color-button-label;
  font-size: styles.$footer-button-font-size;

  & div {
    display: flex;
    width: 30px;
    height: 30px;
    padding-bottom: 5px;
    justify-content: center;
    align-items: center;
  }

  & .setting-audio-icon {
    mask: url('../../../assets/settings_voice.svg') no-repeat center center /
      contain;
    -webkit-mask: url('../../../assets/settings_voice.svg') no-repeat center
      center / contain;
    background: colors.$color-setting-audio;
  }

  @media (hover: hover) {
    &:hover {
      & .setting-audio-icon {
        background: colors.$color-setting-audio-hover;
      }
      cursor: pointer;
      color: colors.$color-setting-audio-hover;
    }
  }

  &:active {
    & .setting-audio-icon {
      background: colors.$color-setting-audio-active;
    }

    color: colors.$color-setting-audio-active;
  }
}
