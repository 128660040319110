$palette-boulder: #808080;
$palette-dodger-blue: #3ed7ff;
$palette-tundora: #404040;
$palette-mercury: #e6e6e6;
$palette-dark-dodger-blue: #19cbff;
$palette-gray: #d1d1d5;
$palette-cyan: #008ee7;
$palette-light-gray: #cccccc;
$palette-shark: #1e1e1e;
$palette-blue-shark: #292a2d;
$palette-coral-red: #ff453a;
$palette-coral-thin-red: #ff736b;
$palette-coral-dark-red: #ff1305;
$palette-scorpion: #726463;
$palette-emerald: #4bd865;
$palette-silver-gray: #b3b3b3;
$palette-medium-gray: #2c2c2d;
$palette-raisin-black: #2a2a2e;
$palette-sky-blue: #00abe8;
$palette-medium-blue: #1d5f80;
$palette-cyan-blue: #0077f0;
$palette-bright-cyan-blue: #006bd6;
$palette-pacific-blue: #00a1de;
$palette-cerulean: #007cab;
$palette-azure-radiance: #0a84ff;
$palette-nobel: #999999;
$palette-eerie-black: #18181a;
$palette-dark-charcoal: #303033;
$palette-very-dark-grayish-blue: #222d33;
$palette-hex-gray: #a3a3a3;
$palette-chathams-blue: #115971;
$palette-cocoa-brown: #2a2021;
$palette-dark-gray: #88888899;
$palette-strong-gray: #bbbbbb52;
$palette-cod-gray: #1a1a1a;
$palette-mine-shaft: #333333;

// HTML基本色12色（グレースケール系なし）
$palette-true-red: #ff0000;
$palette-true-maroon: #800000;
$palette-true-yellow: #ffff00;
$palette-true-olive: #808000;
$palette-true-lime: #00ff00;
$palette-true-green: #008000;
$palette-true-aqua: #00ffff;
$palette-true-teal: #008080;
$palette-true-blue: #0000ff;
$palette-true-navy: #000080;
$palette-true-fuchsia: #ff00ff;
$palette-true-purple: #800080;
$palette-true-white: #ffffff;
$palette-true-black: #000000;
